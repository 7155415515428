import React from 'react';
import { Link } from 'react-router-dom';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/my-pic.png`} alt="" />
      </Link>
      <header>
        <h2>Chems-eddine Sediri</h2>
        <p>
          <a href="mailto:chemseddine.sediri@gmail.com">
            chemseddine.sediri@gmail.com
          </a>
        </p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>
        Hi, I’m Chemseddine. I am a Product Manager with a strong technical
        background in full-stack development, experienced in both B2B and B2C
        environments, and a proven track record in startups. I currently lead my
        side project
        <a href="https://ekrili-dz.com" target="__blank">
          Ekrili-dz.com
        </a>
        , a short-term rental platform that attracted 42,000 visitors and 6,000
        active users in its first three months, earning the Innovative Label
        from the Ministry of Startups.
      </p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? (
            <Link to="/resume" className="button">
              Learn More
            </Link>
          ) : (
            <Link to="/about" className="button">
              About Me
            </Link>
          )}
        </li>
      </ul>
    </section>
  </section>
);

export default SideBar;
